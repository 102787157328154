import React from "react";
import "./logolayout.css";
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../form/button/button";
export default function LogoLayout(props: {
  progress?: number;
  hideBack?: boolean;
  children?: any;
  onSubmit?: any;
  submitTitle?: string;
}) {
  const canGoBack = window.history.state.idx !== 0;
  const methods = useForm({ mode: "onBlur" });
  return (
    <div className="row logolayout">
      <div className="col-md-6">
        <div className="topelement">
          <img className="im1" src="/images/ele1.png" alt="" />
        </div>
        <div className="logo">
          <img src="/images/Logo.svg" alt="" />
          <h4 style={{fontSize:30,marginLeft:-1}} className="logotitle">HYKONNECT</h4>
        </div>
        <div className="bottomelement">
          <img className="im2" src="/images/ele2.png" alt="" />
        </div>
      </div>
      <div className="col formsection d-flex align-items-center justify-content-center ">
        <div className="col">
          <div>
            {canGoBack && !props.hideBack && (
              <Link to={-1 as any}>
                <div className="backcontainer">
                  <img className="backimg" src="/images/left-arrow.svg" alt="" />
                  <h2 className="backtext">Retour</h2>
                </div>
              </Link>
            )}
          </div>
          <FormProvider {...methods}>
            {props.children}
            {props.onSubmit ? (
              <div className="bottom-container">
                <Button
                  onClick={methods.handleSubmit(props?.onSubmit)}
                  className="btn-primary"
                  title={props.submitTitle || "Suivant"}
                />
              </div>
            ) : null}
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
