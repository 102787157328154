/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/table";
import { useParams } from "react-router-dom";
import * as _ from "lodash";
import { cavaliercolumns, prestatairecolumns, structurecolumns } from "./columns";
export default function UsersListPage({ userrole }: any) {
  const role: any = userrole;
  const [refresh, setrefresh] = useState(false);
  function getColumns() {
    if (role == "structures") return structurecolumns;
    else if (role == "prestataires") return prestatairecolumns;
    else return cavaliercolumns;
  }
  useEffect(() => {
    setrefresh(false);
    setTimeout(() => {
      setrefresh(true);
    }, 100);
  }, [role]);

  return refresh ? (
    <Table
      dataUrl={"/users?role=" + role?.slice(0, -1)+"&showDeleted=true"}
      deleteUrl={"/users"}
      columns={getColumns()}
      deleteModalDesc={
        <>
          <span>Vous êtes sur le point de supprimer cet utilisateur, et l’ensemble de ses informations.</span>
          <div style={{ marginTop: 7, marginBottom: 6 }}>
            Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.
          </div>
        </>
      }
      deleteModalTitle={"Supprimer un utilisateur"}
      title={role == "cavaliers" ? "Cavaliers/Propriétaires" : _.capitalize(role)}
    />
  ) : null;
}
