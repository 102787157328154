import moment from "moment";
import React from "react";
import { formatPhone } from "../../../utils/utils";
export function getFactureTagClass(val: any, big = false) {
  return (!big ? "tag " : "") + (val == "Abonné" || val=='Active' ? "tagsuccess" : "tagerror");
}
export const structurecolumns = [
  {
    accessorKey: "status",
    header: "Statut",
    enableColumnFilters: true,
    enableSorting:true,
    size: 20,
    Cell: ({ cell, row }: any) => {
      return (
        <span style={{ height: 40 }} className={getFactureTagClass(cell.getValue())}>
          {cell.getValue()}
        </span>
      );
    },
  },
  {
    header: "Abonnement",
    enableColumnFilters: false,
    size:20,
    enableSorting:false,
    accessorFn: (originalRow: any) => originalRow?.statusSubscribe,
    Cell: ({ cell, row }: any) => {
      return (
        <span style={{ height: 40 }} className={getFactureTagClass(cell.getValue())}>
          {cell.getValue()}
        </span>
      );
    },
  },
  {
    accessorKey: "structure.name",
    enableColumnFilters: false,
    enableSorting:false,
    header: "Nom",
  },
  {
    accessorFn: (originalRow: any) => originalRow?.structure?.type?.toString(),
    header: "Type",
    enableColumnFilters: false,
    enableSorting:false,
  },
  {
    accessorKey: "email",
    header: "E-mail",
    enableColumnFilters: false,
    enableSorting:false,
    size: 300,
  },

  {
    accessorKey: "structure.phone",
    header: "Téléphone",
    enableColumnFilters: false,
    Cell: ({ cell }: any) => formatPhone(cell.getValue()),
    enableSorting:false,
  },

  {
    accessorFn: (originalRow: any) => new Date(originalRow.createdAt),
    header: "Date de création",
    filterVariant: "date-range",
    enableColumnFilters: false,
    sortingFn: "datetime",
    Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
    type: "date",
  },
];

export const cavaliercolumns = [
  {
    accessorKey: "status",
    header: "Statut",
    enableColumnFilters: true,
    enableSorting:true,
    size: 20,
    Cell: ({ cell, row }: any) => {
      return (
        <span style={{ height: 40 }} className={getFactureTagClass(cell.getValue())}>
          {cell.getValue()}
        </span>
      );
    },
  },

  {
    accessorKey: "first_name",
    header: "Prénom",
    enableColumnFilters: false,
    enableSorting:false,
  },
  {
    accessorKey: "last_name",
    header: "Nom",
    enableColumnFilters: false,
    enableSorting:false,
  },
  {
    accessorKey: "email",
    header: "E-mail",
    enableColumnFilters: false,
    enableSorting:false,
  },

  {
    accessorKey: "phone",
    header: "Téléphone",
    enableColumnFilters: false,
    Cell: ({ cell }: any) => formatPhone(cell.getValue()),
    enableSorting:false,
  },
  // {
  //   accessorKey: "level",
  //   header: "Niveau",
  // },
  // {
  //   accessorKey: "galop",
  //   header: "Galop",
  // },
  // {
  //   accessorFn: (originalRow: any) => new Date(originalRow.birth_date),
  //   header: "Date de naissance",
  //   filterVariant: "date-range",
  //   sortingFn: "datetime",
  //   Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
  //   type: "date",
  // },

  {
    accessorFn: (originalRow: any) => new Date(originalRow.createdAt),
    header: "Date de création",
    filterVariant: "date-range",
    sortingFn: "datetime",
    Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
    enableColumnFilters: false,
    type: "date",
  },
  // {
  //   accessorKey: "address.address",
  //   header: "Adresse",
  // },
  // {
  //   accessorKey: "tuteur.first_name",
  //   header: "Prénom tuteur",
  // },
  // {
  //   accessorKey: "tuteur.last_name",
  //   header: "Nom tuteur",
  // },
  // {
  //   accessorKey: "tuteur.email",
  //   header: "E-mail tuteur",
  // },
  // {
  //   accessorKey: "tuteur.phone",
  //   header: "Téléphone tuteur",
  // },
  // {
  //   accessorKey: "tuteur.address.address",
  //   header: "Adresse tuteur",
  // },
];

export const prestatairecolumns = [
  {
    accessorKey: "status",
    header: "Statut",
    enableColumnFilters: true,
    enableSorting: true,
    size: 20,
    Cell: ({ cell, row }: any) => {
      return (
        <span style={{ height: 40 }} className={getFactureTagClass(cell.getValue())}>
          {cell.getValue()}
        </span>
      );
    },
  },
  {
    header: "Abonnement",
    enableColumnFilters: false,
    size:20,
    enableSorting:false,
    accessorFn: (originalRow: any) => originalRow?.statusSubscribe,
    Cell: ({ cell }: any) => {
      return (
        <span style={{ height: 40 }} className={getFactureTagClass(cell.getValue())}>
          {cell.getValue()}
        </span>
      );
    },
  },
  {
    accessorKey: "first_name",
    header: "Prénom",
    enableColumnFilters: false,
    enableSorting:false,
  },
  {
    accessorKey: "last_name",
    header: "Nom",
    enableColumnFilters: false,
    enableSorting:false,
  },
  {
    accessorKey: "email",
    header: "E-mail",
    enableColumnFilters: false,
    enableSorting:false,
  },

  {
    accessorKey: "phone",
    header: "Téléphone",
    Cell: ({ cell }: any) => formatPhone(cell.getValue()),
    enableColumnFilters: false,
    enableSorting:false,
  },

  {
    accessorFn: (originalRow: any) => new Date(originalRow.createdAt),
    header: "Date de création",
    filterVariant: "date-range",
    sortingFn: "datetime",
    Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
    type: "date",
  },
];
