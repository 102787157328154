import React from "react";
import Sidebar from "../components/sidebar/sidebar";
import { Navigate, Route, Routes } from "react-router-dom";


import UserPage from "../pages/admin/users/user.page";
import UsersListPage from "../pages/admin/users/userList.page";
import Page404 from "../pages/404/404.page";
import SignalListPage from "../pages/admin/signal/lists.page";
export default function AdminRoutes() {
  return (
    <div className="row" style={{ height: "100%", margin: 0, padding: 0 }}>
      <div style={{ padding: 0, margin: 0 }} className="col-auto">
        <Sidebar />
      </div>
      <div className="page col-md-8">
        <Routes>
         <Route path="" element={<Navigate to={"users"} />} />
          <Route path="/users">
            <Route path="" element={<Navigate to={"cavaliers"} />} />
            <Route path="cavaliers" element={<UsersListPage userrole={"cavaliers"} />} />
            <Route path="structures" element={<UsersListPage userrole={"structures"} />} />
            <Route path="prestataires" element={<UsersListPage userrole={"prestataires"}  />} />
            <Route path=":role/:id" element={<UserPage />} />
          </Route>
          <Route path="/signals" element={<SignalListPage/>}/>
          <Route path="*" element={<Page404/>} />
        </Routes>
      </div>
    </div>
  );
}
