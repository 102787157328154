import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { emailReg, passwordReg } from "../../../utils/regex";
import FormLayout from "../../../components/formlayout/formlayout";
import { showToast } from "../../../utils/utils";
import { capitalize } from "lodash";
import axiosInstance from "../../../config/axios";
import moment from "moment";
import Modal from "../../../components/modal/modal";
import Button from "../../../components/form/button/button";
export default function UserPage() {
  const { role, id } = useParams();
  const [user, setuser]:any = useState({});
  const [loading, setloading] = useState(false);
  const [openmodal, setopenmodal] = useState(false);
  const navigate = useNavigate();
  async function onConfirmRemove() {
    try {
      await axiosInstance.delete("users/" + id);
      setopenmodal(false);
      navigate(-1);
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const { data } = await axiosInstance.get("users/" + id);
        setuser(data);
      } catch (e) {
        showToast("Une erreur est survenue");
      }
      setloading(false);
    })();
  }, []);
  return (
    <>
      <FormLayout loading={loading}>
        <div style={{ pointerEvents: "none" }}>
          {role == "structures" ? <FormStructure user={user} /> : null}
          {role == "cavaliers" ? <FormCavalier user={user} /> : null}
          {role == "prestataires" ? <FormPrestataire user={user} /> : null}
        </div>
      </FormLayout>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-10"></div>
        <div className="col">
          <Button disabled={user?.status=="Supprimée"} onClick={() => setopenmodal(true)} className="btn-secondary" title="Supprimer" />
          <Modal
            show={openmodal}
            description={
              <>
                <span>Vous êtes sur le point de supprimer cet utilisateur, et l’ensemble de ses informations.</span>
                <div style={{ marginTop: 7, marginBottom: 6 }}>
                  Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.
                </div>
              </>
            }
            title={"Supprimer un utilisateur"}
            onConfirm={onConfirmRemove}
            onClose={() => setopenmodal(false)}
          />
        </div>
      </div>
    </>
  );
}

function FormCavalier({ user }: any) {
  return (
    <>
      <div className="row graycard">
        <h2>Informations</h2>
        <div className="col">
          <TextInput label="Prénom" value={user.first_name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Nom" value={user.last_name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="E-mail" value={user.email} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Téléphone" value={user.phone} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Galop" value={user.galop} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Niveau" value={user.level} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Adresse" value={user.address?.address} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Date de naissance" value={moment(user.birth_date).format("DD/MM/YYYY")} />
        </div>
      </div>
      <div className="row graycard" style={{ marginTop: 30 }}>
        <h2>Tuteur</h2>
        <div className="col">
          <TextInput label="Prénom" value={user?.tuteur?.first_name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Nom" value={user?.tuteur?.first_name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="E-mail" value={user?.tuteur?.email} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Téléphone" value={user?.tuteur?.phone} />
        </div>

        <div className="col-sm-12 col-md-4">
          <TextInput label="Date de naissance" value={user?.tuteur?moment(user?.tuteur?.birth_date).format("DD/MM/YYYY"):undefined} />
        </div>
      </div>
    </>
  );
}
function FormPrestataire({ user }: any) {
  return (
    <div className="row graycard">
      <h2>Informations</h2>
      <div className="col">
        <TextInput label="Prénom" value={user.first_name} />
      </div>
      <div className="col-sm-12 col-md-4">
        <TextInput label="Nom" value={user.last_name} />
      </div>
      <div className="col-sm-12 col-md-4">
        <TextInput label="E-mail" value={user.email} />
      </div>
      <div className="col-sm-12 col-md-4">
        <TextInput label="Téléphone" value={user.phone} />
      </div>
      <div className="col-sm-12 col-md-4">
        <TextInput label="Profession" value={user.job} />
      </div>
      <div className="col-sm-12 col-md-4">
        <TextInput label="Adresse" value={user?.address?.address} />
      </div>
      <div className="col-sm-12 col-md-4">
        <TextInput label="Date de naissance" value={moment(user.birth_date).format("DD/MM/YYYY")} />
      </div>
    </div>
  );
}
function FormStructure({ user }: any) {
  return (
    <>
      <div className="row graycard">
        <h2>Informations</h2>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Nom" value={user.structure?.name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Type" value={user.structure?.type} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="E-mail" value={user.email} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Téléphone" value={user.structure?.phone} />
        </div>
      </div>
      <div className="row graycard" style={{ marginTop: 30 }}>
        <h2>Gérant</h2>
        <div className="col">
          <TextInput label="Prénom" value={user?.manager?.first_name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Nom" value={user?.manager?.first_name} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="E-mail" value={user?.manager?.email} />
        </div>
        <div className="col-sm-12 col-md-4">
          <TextInput label="Téléphone" value={user?.manager?.phone} />
        </div>
      </div>
    </>
  );
}
