import React, { useEffect, useMemo, useState } from "react";
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import "./table.css";
import Button from "../form/button/button";
import "react-responsive-modal/styles.css";
import Modal from "../modal/modal";
import "dayjs/locale/de";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/en-gb";
import { Link } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { showToast } from "../../utils/utils";

export default function Table(props: {
  title?: string;
  columns?: any;
  data?: any[];
  deleteUrl?: string;
  isLoading?: boolean;
  isSaving?: boolean;
  onRemove?: Function;
  dataUrl?: any;
  renderRowAction?: any;
  deleteModalTitle?: any;
  deleteModalDesc?: any;
  hideAdd?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  hideAction?: boolean;
}) {
  const [openmodal, setopenmodal] = useState(false);

  const [selectedrow, setselectedrow] = useState("");
  const [data, setdata] = useState(props.data);
  const [isSaving, setIsSaving] = useState(props.isSaving);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [pagination,setpagination]=useState({pageSize:25,pageIndex:0})
  useEffect(() => {
    if (props.dataUrl) {
      (async () => {
        setIsLoading(true);
        try {
          const result = await axiosInstance.get(props.dataUrl);
          const d = result.data.data || result.data;
          setdata(d);
        } catch (e) {
          showToast("Une erreur est survenue");
        }
        setIsLoading(false);
      })();
    } else setdata(props.data);
  }, [props.data, props.dataUrl]);

  function onRemove(row: any) {
    setselectedrow(row._id);
    setopenmodal(true);
  }

  async function onConfirmRemove() {
    try {
      setIsSaving(true);
      if (!props.onRemove)
        try {
          await axiosInstance.delete((props.deleteUrl || props.dataUrl) + "/" + selectedrow);
        } catch (e) {}
      else await props.onRemove(selectedrow);
      setIsSaving(false);
      setdata(data?.filter((elt) => (elt._id || elt.id) != selectedrow));
      setopenmodal(false);
      setselectedrow("");
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  const table = useMaterialReactTable({
    columns: props.columns,
    data: data || [],
    state: {
      isLoading: isLoading,
      pagination:pagination,
      isSaving: isSaving,
    },

    localization: MRT_Localization_FR,
    enableRowNumbers: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: false,
    enableTopToolbar: true,
    enableMultiRowSelection: true,
    enableColumnPinning: false,
    enableColumnOrdering: false,
    onPaginationChange:setpagination,
    renderTopToolbarCustomActions(p) {
      return (
        <div className="row top" style={{ marginBottom: 20 }}>
          <div className="col">
            <h2 className="subtitle" style={{ lineHeight: undefined }}>
              {props.title}
              {data && <span className="indicator">{data.length}</span>}
            </h2>
            {state.globalFilter || state.columnFilters?.length ? (
              <span className="legend">
                {table.getFilteredRowModel().rows.length} {props.title?.toLowerCase()} trouvés
              </span>
            ) : null}
          </div>
        </div>
      );
    },
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionActionsColumn: "last",
    enableEditing: false,
    editDisplayMode: "modal",
    enableToolbarInternalActions: true,
    enableRowActions: !props.hideAction,
    renderRowActions: !props.hideAction
      ? props.renderRowAction
        ? props.renderRowAction
        : (p) => (
            <div style={{ display: "flex" }}>
              {!props.hideDelete && (
                <span
                  style={{
                    cursor: "pointer",
                    pointerEvents: p.row.original.status == "Supprimée" ? "none" : "auto",
                    opacity: p.row.original.status == "Supprimée" ? 0.5 : 1,
                  }}
                  className="action"
                  onClick={() => onRemove(p.row.original)}
                >
                  <img src="/images/remove.svg" alt="supprimer" />
                </span>
              )}
              {!props.hideEdit && (
                <Link to={p.row.original._id}>
                  <span className="action">
                    <img style={{ marginLeft: 10 }} src="/images/facteye.svg" alt="modifier" />
                  </span>
                </Link>
              )}
            </div>
          )
      : undefined,
    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      className: staticRowIndex % 2 == 0 ? "rowpair" : "rowimpair",
    }),
    enableColumnActions: false,
  });
  const state = table.getState();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <div className="apptable">
        <MaterialReactTable table={table} />
        <Modal
          show={openmodal}
          title={props.deleteModalTitle || ""}
          onConfirm={onConfirmRemove}
          description={props.deleteModalDesc}
          onClose={() => setopenmodal(false)}
        />
      </div>
    </LocalizationProvider>
  );
}
