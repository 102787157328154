/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Table from "../../../components/table/table";
import * as _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
export default function SignalListPage() {
  const columns: any = [
    {
      accessorKey: "title",
      header: "Motif",
      enableSorting: false,
    },
    {
      accessorFn: (originalRow: any) =>
        (originalRow.isSender?.first_name || "") + " " + (originalRow.isSender?.last_name || ""),
      header: "Auteur",
      enableSorting: false,
    },
    {
      accessorFn: (originalRow: any) =>
        (originalRow.idReceiver?.first_name || "") + " " + (originalRow.idReceiver?.last_name || ""),

      header: "Signalé",
      enableSorting: false,
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.createdAt),
      header: "Date de création",
      filterVariant: "date-range",

      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
  ];
  const navigate = useNavigate();
  return (
    <Table
      dataUrl={"/signal"}
      columns={columns}
      renderRowAction={(p: any) => {
        const userole = p.row.original.idReceiver.role == "Prestataire" ? "prestataires" : "cavaliers";

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/users/" + userole + "/" + p.row.original.idReceiver._id)}
          >
            <span style={{ marginLeft: 20 }} className="action">
              <img src={"/images/facteye.svg"} alt="download" />
            </span>
          </div>
        );
      }}
      title={"Signalements"}
    />
  );
}
