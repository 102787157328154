import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
import Button from "../form/button/button";
export default function Sidebarr() {
  const { setAppInfos } = useAppContext();
  function onLogout() {
    setAppInfos({ user: null, token: null });
  }

  return (
    <OffCanva>
      <Sidebar className="sidebar">
        <div className="container">
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"></button>
          <Link to="/">
            <div className="logo">
              <img src="/images/Logo.svg" alt="" />
            </div>
          </Link>
          <Menu className="menu">
            <SidebarMenu nested label="Utilisateurs" route="/users">
              <SidebarItem route={"/users/cavaliers"} label="Cavaliers/Propriétaires" />
              <SidebarItem route={"/users/prestataires"} label="Prestataires" />
              <SidebarItem route={"/users/structures"} label="Structures" />
            </SidebarMenu>
            <SidebarMenu route={"/signals"} label="Signalement" />
          </Menu>
          <Button className="logout" onClick={onLogout} title="Se déconnecter" />
        </div>
      </Sidebar>
    </OffCanva>
  );
}

function SidebarMenu(props: any) {
  const location = useLocation();
  const active = props.route && location.pathname.indexOf(props.route) != -1;
  const [toggle, settoggle] = useState(active);

  useEffect(() => {
    if (!active) settoggle(false);
  }, [active]);
  return (
    <SubMenu
      className={`submenu  ${!props.nested ? "submenu-single" : ""} ${active ? "submenu-active" : ""}`}
      component={props.route && !props.nested && <Link to={props.route} />}
      open={toggle}
      onClick={() => settoggle((old:any) => !old)}
      {...props}
      icon={props.icon && <img className="icon" src={props.icon} alt="" />}
    >
      {props.children}
    </SubMenu>
  );
}

function SidebarItem(props: any) {
  const location = useLocation();
  const active = props.route && location.pathname.indexOf(props.route) != -1;
  return (
    <MenuItem
      component={props.route && <Link to={props.route} />}
      className={`menuitem ${active ? "menuitem-active" : ""}`}
      {...props}
      icon={props.icon && <img className="icon" src={props.icon} alt="" />}
    >
      <span> • &nbsp; {props.label || props.children}</span>
    </MenuItem>
  );
}

function OffCanva(props: any) {
  return (
    <div>
      <div className="togglebar">
        <a
          className="toggle"
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          <img style={{ width: 24, marginRight: 20 }} src="/images/menu.svg" alt="" />
        </a>
        <Link to="/">
          <div className="logo">
            <img style={{ width: 40 }} src="/images/Logo.svg" alt="" />
          </div>
        </Link>
      </div>
      <div className="offcanvas " tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div style={{ padding: 0, margin: 0 }} className="offcanvas-body">
          {props.children}
        </div>
      </div>
      <div className="sidecontent">{props.children}</div>
    </div>
  );
}
