/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../form/button/button";
import { Link } from "react-router-dom";
import Modal from "../modal/modal";
import axiosInstance from "../../config/axios";

export default function FormLayout(props: any) {
  const methods = useForm({ mode: "onBlur" });
 
  return (
    <div style={{ padding: 24, overflowX: "hidden" }}>
      <Link to={-1 as any}>
        <div className="backcontainer">
          <img className="backimg" src={"/images/left-arrow.svg"} alt="" />
          <h2 style={{ color: "#04141c" }} className="backtext">
            Retour
          </h2>
        </div>
      </Link>
      <div className="row">
        <FormProvider {...methods}>
          {props.loading ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",

                justifyContent: "center",
                zIndex: 4,
                alignItems: "center",
              }}
            >
              <div style={{ color: "#e37461", width: 40, height: 40 }} className="spinner-border" role="status"></div>
            </div>
          ) : null}
          {props.children}
        </FormProvider>
      </div>
    </div>
  );
}
